import React,{useEffect} from "react";
import "../../../../src/theme/css/HomePage.css";
import { Row, Col } from "react-bootstrap";
import Feature1 from "../../../assets/Images/OurFeatures/Feature1.png";
import Feature2 from "../../../assets/Images/OurFeatures/Feature2.png";
import Feature3 from "../../../assets/Images/OurFeatures/Feature3.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

export default function OurFeature() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
    <div className=" ourFeatureImg">
        <div className="container">
          <div className="ourFeature">
            <div className="titleFont text-center" data-aos="zoom-in-down">
              <h3>What We Do</h3>
              <h2>Our Services</h2>
            </div>
            <div className="mainFeaturesCard">
              <Row>
                <Col xs={12} sm={4}>
                  <div className="featuresCard"  data-aos="zoom-in">
                    <div className="featuresCardCircleArea">
                      <div className="featuresCardCircle">
                        <img className="img-fluid mx-auto" src={Feature1} alt="OurFeature" />
                      </div>         
                    </div>
                    <h3>Maintenance</h3>
                    <div className="featuresCardContain">
                      <h6>
                        Maintaining the safety and security system is very important to protect lives and property from fire. 
                        We alway ensure to keep all the protection systems are  functioning properly.
                      </h6>
                      <details className="details">
                        <summary className="summary">
                          <span id="open">Read More</span>
                          <span id="close">Less</span>
                        </summary>

                        <h6 className="maskbtnOpenText">
                          We do maintenance of all types of extinguishers (portable & mobile) of :
                          <ul>
                            <li>Dry Chemical Powder</li>
                            <li>Carbon Dioxide</li>
                            <li>Foam</li>
                            <li>water</li>
                          </ul>
                        </h6>
                      </details>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <div className="featuresCard"  data-aos="zoom-in">
                    <div className="featuresCardCircleArea">
                      <div className="featuresCardCircle">
                        <img className="img-fluid mx-auto" src={Feature2} alt="OurFeature" />
                      </div>
                    </div>
                    <h3>Installation</h3>
                    <div className="featuresCardContain">
                      <h6>
                        We do installations of all kinds of fire safety products to prevent/control the damage caused by fire emergencies. 
                        We always focus on getting the advantage of latest technologies! 
                        
                      </h6>
                      <details className="details">
                        <summary className="summary">
                          <span id="open">Read More</span>
                          <span id="close">Less</span>
                        </summary>

                        <h6 className="maskbtnOpenText">

                          <ul>
                            <li>Fire hose reels and cabinets</li>
                            <li>Fire hydrants</li>
                            <li>Sprinkler systems</li>
                            <li>Alarm systems</li>
                            <li>Fire Detection Systems</li>
                            <li>Foam Flooding Systems</li>
                            <li>CO<sub>2</sub> Flooding Systems</li>
                            <li>Fire Doors</li>
                            <li>Lightning Arrestor Systems</li>
                          </ul>
                        </h6>
                      </details>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <div className="featuresCard"  data-aos="zoom-in">
                    <div className="featuresCardCircleArea">
                      <div className="featuresCardCircle">
                        <img className="img-fluid mx-auto" src={Feature3} alt="OurFeature" />
                      </div>
                    </div>
                    <h3>Training & Demonstrations</h3>
                    <div className="featuresCardContain">
                      <h6>
                        Before a fire occurs, it is important to be prepared with emergency procedure plans and training.
                        We will provide you a customized emergency management procedure to suit your requirements. 
                      </h6>
                      <details className="details">
                        <summary className="summary">
                          <span id="open">Read More</span>
                          <span id="close">Less</span>
                        </summary>

                        <h6 className="maskbtnOpenText">
                        We will do fully detailed training and demonstrations of fire safety equipments, evacuation plans, and all the necessary procedures.
                        </h6>
                      </details>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <button className="homeBannerBtn ourSurvice"><Link to="/service">All Services</Link></button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
