import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import About1 from "../../../assets/Images/AboutUs/About1.png";
import About2 from "../../../assets/Images/AboutUs/About2.png";
import AboutSlider1 from "../../../assets/Images/AboutUs/AboutSlider1.jpg";
import AboutSlider2 from "../../../assets/Images/AboutUs/AboutSlider2.jpg";
import AboutSlider3 from "../../../assets/Images/AboutUs/AboutSlider3.jpg";
import AboutSlider4 from "../../../assets/Images/AboutUs/AboutSlider4.jpg";
import { Link } from "react-router-dom";





export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      slide: ".slick-slideshow__slide",
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "110px",
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 800,
          settings: {
            centerMode: false,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: false,
            slidesToShow: 2,

          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: false,
          },
        },
      ],
    };
    
    return (
      <>
        <div className="container">
          <div className="mainAboutUs">
            <Row>
              <Col xl={6} lg={6} data-aos="fade-up">
                <div className="aboutSliderDiv">
                <Slider className="aboutSlider" {...settings}>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider2}
                      alt="AboutSlider"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider3}
                      alt="AboutSlider"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider1}
                      alt="AboutSlider"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid mx-auto"
                      src={AboutSlider4}
                      alt="AboutSlider"
                    />
                  </div>
                </Slider>
                </div>
              </Col>

              <Col xl={6} lg={6} data-aos="fade-up">
                <div className="aboutUs">
                  <div className="titleFont">
                    <h3>About Us</h3>
                    <h2>Business giants are under our protection!</h2>
                    <h5>
                      Most of the leading businesses in Sri Lanka are now protected with our extinguishers & other safety equipments. We are providing continues service to keep their employees and properties safe from fire damages.
                    </h5>
                  </div>
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"
                          src={About1}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    <div className="aboutUsIconSideText">
                      <h6> We Use The Latest Technologies</h6>
                      <p>
                        We always use latest technologies to provide the best solution.  
                      </p>
                    </div>
                  </div>
                  <div className="aboutIconMainDivFloat">
                    <div className="aboutIconMainDiv">
                      <div className="aboutIconCircle">
                        <img
                          className="img-fluid mx-auto"                        
                          src={About2}
                          alt="OurFeature"
                        />
                      </div>
                    </div>
                    <div className="aboutUsIconSideText">
                      <h6> We Provide The Best Service </h6>
                      <p>
                        Our well experienced team will alway provide the best service to prevent fire damages.
                      </p>
                    </div>
                  </div>
                  <div className="bannerBtn">
                    <button className="ourSurvice"><Link to="/aboutus">More About Us</Link></button>
                    <button className="ourContact"><a href="https://www.facebook.com/profile.php?id=100057341632665&sk=photos"  target="_blank">More Photos</a></button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
