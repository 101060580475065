import team1 from '../../../assets/Images/Team/team1.png';
import team2 from '../../../assets/Images/Team/team2.png';
import team3 from '../../../assets/Images/Team/team3.png';
import team4 from '../../../assets/Images/Team/team4.png';

export const OurTeamImg = [
  {
    id: 1,
    image: team1,
    title: "Monika Kapoor",
    subtitle: "Assistant Manager",
  },
  {
    id: 2,
    image: team2,
    title: "Robin Roy",
    subtitle: "CEO",
  },
  {
    id: 3,
    image: team3,
    title: "Rubina Kapoor",
    subtitle: "Project Manager",
  },
  {
    id: 4,
    image: team4,
    title: "Marteen Ray",
    subtitle: "Manager",
  },
  {
    id: 5,
    image: team1,
    title: "Monika Kapoor",
    subtitle: "Assistant Manager",
  },
  {
    id: 6,
    image: team2,
    title: "Robin Roy",
    subtitle: "CEO",
  },
  {
    id: 7,
    image: team3,
    title: "Rubina Kapoor",
    subtitle: "Project Manager",
  },
  {
    id: 8,
    image: team4,
    title: "Marteen Ray",
    subtitle: "Manager",
  },
];
export default OurTeamImg;