import React from "react";
import "../../../../src/theme/css/HomePage.css";
import MainBannerImg from "../../../assets/Images/Banner/MainBannerImg.png";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <div className="mainBannerDiv">
        <img className="img-fluid mx-auto" src={MainBannerImg} alt="BannerImage" />
        <div className="bannerContain">
          <h6>Fire is a good servant, but bad master!</h6>
          <h1>Think Of FIRE Before It Starts!</h1>
          <h5>
            We are here to prevent/reduces the risk of injury and building damage that fires can cause. 
            Developing and implementing fire safety protocols in the workplace is crucial to everyone's safety 
            that may be in the building during a fire emergency.
          </h5>
          <div className="bannerBtn">
          <button className="ourSurvice"><Link to="/aboutus">Our Service </Link></button>
          <button className="ourContact"><Link to="">Our Contact</Link></button>
          </div>
        </div>
      </div>
    </>
  );
}
