import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/Images/Choose/Icon1.png";
import Icon2 from "../../../assets/Images/Choose/Icon2.png";
import Icon3 from "../../../assets/Images/Choose/Icon3.png";
import Icon4 from "../../../assets/Images/Choose/Icon4.png";
import CountUp from "react-countup";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ChooseUs() {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, []);
  return (
    <>
    <div className="chooseBanner">
        <div className="chooseBanneImg">
          <div className="container">
            <Row className="align-items-center">
              <Col xl={7} lg={7} md={6} sm={12} data-aos="fade-up">
                <div className="titleFont">
                  <h3>Why Choose Us</h3>
                  <h2>We prevent fire! </h2>
                </div>
                <h5>
                  We are a pro active in providing total fire protection solutions, having a state-of-the-art workshop and a team of qualified professionals to provide fire protection solutions in a most economical way.
                  Our reputation for quality and reliability has made it trusted name throughout Sri Lanka!
                  </h5>
                {/* <button className="button">
                  <Link to="/service">View Projects</Link>
                </button> */}
              </Col>
              <Col xl={5} lg={5} md={6} sm={12} data-aos="flip-down">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="chooseBox text-center">
                      <img
                        className="img-fluid mx-auto"
                        alt="Choose us"
                        src={Icon1}
                      />
                      <h3>
                        <CountUp end={140} duration={2.74} />
                      </h3>
                      <h6>Happy Clients</h6>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="chooseBox text-center">
                      <img
                        className="img-fluid mx-auto"
                        alt="Choose us"
                        src={Icon2}
                      />
                      <h3>
                        <CountUp end={2400} duration={2.75} />
                      </h3>
                      <h6>Installations</h6>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="chooseBox text-center">
                      <img
                        className="img-fluid mx-auto"
                        alt="Choose us"
                        src={Icon3}
                      />
                      <h3>
                        <CountUp end={7000} duration={2.75} />
                      </h3>
                      <h6>Safe Lives</h6>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="chooseBox text-center">
                      <img
                        className="img-fluid mx-auto"
                        alt="Choose us"
                        src={Icon4}
                      />
                      <h3>
                        <CountUp end={4} duration={2.75} />
                      </h3>
                      <h6>Years Experience</h6>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
