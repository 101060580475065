import React from 'react';
import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox">
            <Col xl={5} lg={3} md={4}>
            <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Free Inspection</h3>
                <span>
                  Please feel free to contact us for a <b>FREE INSPECTION</b> of fire extinguishers & other safety equipments
                </span>
                <div className="FooterquickContact">
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>Bandara - <a href="tel:+94772650737">077 265 0737</a></span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>Dameesha - <a href="tel:+94719112741">071 911 2741</a></span>
                  </div>
                  </div>
              </div>
            </Col>
            <Col xl={5} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Contact Us
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>
                </h3>
                <Collapse in={Copen}>
                <div className="FooterquickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>160/11/B, New Kandy Road,</span>
                    <span>Bandara Watta Junction, Biyagama,</span>
                    <span>Kaduwela.</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span><a href="tel:+94113671515">011 367 1515</a>/<a href="tel:+94113671572">011 367 1572</a></span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span><a href='email:info@uksafety.lk'>info@uksafety.lk</a></span>
                  </div>
                </div>
                </Collapse>
              </div>
            </Col>
            <Col xl={2} lg={3} md={4}>
              <div className="F-col-4 footer-text">
                <h3>Follow Us </h3>
                <ul className="list-unstyled social-icon">
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fa fa-google"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
      
        <h6 className="F-copywrite">
          Made with <i class="heart fa fa-heart pulse"></i> by <a href="https://ensure.lk" target="_blank">Ensure Business Solutions.</a>
        </h6>
      </div>
    </>
  );
};
export default Footer;
